<!--
 * @version: 1.0
 * @Date: 2023-04-23 14:17:32
 * @LastEditors: chenxu chenxu@libawall.cn
-->
<template>
  <div>
    <!-- 表单 -->
    <a-form :model="formData" layout="vertical" class="form">
      <a-form-item label="审批人设置" class="form-app">
        <a-radio-group v-model:value="approvalSet" :disabled="allDisabled">
          <a-radio value="1">指定人员</a-radio>
          <a-radio value="2">部门主管</a-radio>
          <a-radio value="3">连续多级审批</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 添加成员 -->
      <div class="person" v-if="approvalSet === '1'">
        <div class="person-header">
          添加成员
          <span class="person-header-right">（最多30位）</span>
        </div>
        <div class="personel">
          <div class="per-item" v-for="(item, index) in assignedUser" :key="item.id">
            {{ item.dataName }}
            <CloseOutlined @click="delPerson(index)" class="item-icon" v-if="!allDisabled" />
          </div>
          <i
            :class="['iconfont', 'icon-add', 'icon-add-right']"
            v-if="assignedUser.length < 30 && !allDisabled"
            @click="addPerson"
          ></i>
        </div>
      </div>
      <!-- 多人审批方式 -->
      <a-form-item label="多人审批时采用的审批方式" v-if="approvalSet === '1' && assignedUser.length >= 2">
        <a-radio-group v-model:value="mode" :disabled="allDisabled">
          <a-radio value="1">会签（需所有审批人同意）</a-radio>
          <a-radio value="2">或签（一名审批人同意即可）</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item v-if="approvalSet !== '1'">
        <template #label>
          指定层级
          <span class="app-text">（自下而上，以发起人部门负责人为第一级）</span>
        </template>
        <a-select v-model:value="level" :disabled="allDisabled">
          <a-select-option :value="me.value" v-for="me in managerList" :key="me.value">{{ me.title }}</a-select-option>
        </a-select>
      </a-form-item>
      <div class="text" v-if="approvalSet === '3'">
        <p>提示：</p>
        <p>
          连续多级审批，选择第3级负责人，则审批将从直接主管-第二级主管-第三级主管依次审批，如果存在审批人存在重复，将去重处理。
        </p>
      </div>
      <a-form-item label="审批人为空时" v-if="selectedNode.type === 'APPROVAL'" style="margin-top: 32px;">
        <a-radio-group v-model:value="nobody" :disabled="allDisabled">
          <a-radio value="1">自动通过</a-radio>
          <a-radio value="2">转交给归档流程负责人</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="审批人与之前节点存在重复时" v-if="selectedNode.type === 'APPROVAL'" style="margin-top: 32px;">
        <a-radio-group v-model:value="repeatPass" :disabled="allDisabled">
          <a-radio value="1">自动通过</a-radio>
          <a-radio value="2">仍然需要审批</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
    <!-- TODO：统一选择成员 -->
    <a-modal
      centered
      title="添加成员"
      width="655px"
      class="global-range-modal"
      :visible="RangeModalVisible"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <GlobalSelector
        :visible="RangeModalVisible"
        :selectedList="assignedUser"
        :selectorInfo="selectorInfo"
        :renderList="renderList"
        @getSearchInfo="getSearchInfo"
        @toNext="toNext"
        @changeData="changeData"
        ref="globalSelector"
      >
      </GlobalSelector>
    </a-modal>
  </div>
</template>

<script>
import { CloseOutlined } from '@ant-design/icons-vue'
import { cmsNotice } from '@/utils/utils'
import GlobalSelector from '@/components/GlobalSelector/index.vue'
import { getDepartmentOrStaff } from '@/apis/businessManage'
import { formateListData, transformData } from '@/utils/selectorListFormate.js'

export default {
  name: 'AppConfig',
  components: {
    CloseOutlined,
    GlobalSelector
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showConfig: {
      type: Boolean,
      defaulte: false
    },
    isCancel: {
      type: Boolean,
      defaulte: false
    }
  },
  data() {
    return {
      RangeModalVisible: false,
      managerList: [
        // 部门主管
        { title: '直接主管', value: 1 },
        { title: '二级主管', value: 2 },
        { title: '三级主管', value: 3 },
        { title: '四级主管', value: 4 },
        { title: '五级主管', value: 5 },
        { title: '六级主管', value: 6 },
        { title: '七级主管', value: 7 },
        { title: '八级主管', value: 8 },
        { title: '九级主管', value: 9 },
        { title: '十级主管', value: 10 }
      ],
      formData: {}, // 发起/审批
      assignedUser: null,
      approvalSet: '1', // 审批人设置
      mode: '1', // 审批类型
      level: 1, // 层级
      nobody: '1', // 审批人为空时
      repeatPass: '2', // 审批人重复
      selectorInfo: {
        placeholder: '请输入员工姓名或部门',
        abelCheckAll: true,
        limitCount: 30,
        ableSelectDep: true,
        ableChangeData: true
      },
      renderList: [],
      selectorType: 1
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.process.selectedNode
    },
    allDisabled() {
      return this.$store.state.process.isPreview
    }
  },
  watch: {
    showConfig: {
      deep: true,
      handler(value) {
        let props = JSON.parse(JSON.stringify(this.selectedNode.props))
        console.log('111111', value)
        if (value) {
          ;(this.mode = props.mode === 'OR' ? '2' : '1'),
            (this.nobody = props.nobody.handler === 'TO_PASS' ? '1' : '2'),
            (this.approvalSet =
              props.assignedType === 'ASSIGN_USER' ? '1' : props.assignedType === 'LEADER' ? '2' : '3'),
            (this.level =
              this.approvalSet === '2'
                ? props?.leader.level
                : this.approvalSet === '3'
                ? props?.leaderTop?.endLevel
                : 1),
            (this.repeatPass = props?.repeatPass ? '1' : '2')
          props.assignedUser.forEach(item => {
            item.dataType = 1
            this.assignedUser.push(item)
          })
        } else {
          if (!this.isCancel) {
            console.log(1111)
          } else {
            // 点了保存
            const typeList = ['ASSIGN_USER', 'LEADER', 'LEADER_TOP']
            // LEADER_TOP 连续多级 // LEADER 主管
            this.selectedNode.props = {
              ...this.selectedNode.props,
              mode: typeList[Number(this.approvalSet) - 1] === 'LEADER_TOP' ? 'NEXT' : this.mode === '1' ? 'AND' : 'OR',
              assignedType: typeList[Number(this.approvalSet) - 1]
            }
            if (this.approvalSet === '2') {
              this.selectedNode.props = {
                ...this.selectedNode.props,
                leader: {
                  level: this.level
                }
              }
            } else if (this.approvalSet === '3') {
              this.selectedNode.props = {
                ...this.selectedNode.props,
                leaderTop: {
                  endLevel: this.level
                }
              }
            }
            this.assignedUser.forEach(item => {
              item.dataType = 'user'
            })
            this.selectedNode.props.assignedUser = this.approvalSet === '1' ? this.assignedUser : []
            this.selectedNode.props.nobody.handler = this.nobody === '1' ? 'TO_PASS' : 'TO_ADMIN'
            this.selectedNode.props.repeatPass = this.repeatPass === '1'
          }
          this.mode = '1'
          this.nobody = '1'
          this.assignedUser = []
          this.approvalSet = '1'
          this.level = '1'
          props = []
        }
      }
    }
  },

  created() {
    this.getScopeList()

    console.log(this.selectedNode.props)
    const props = JSON.parse(JSON.stringify(this.selectedNode.props))
    const approvalType = props?.assignedType
    props?.assignedUser?.forEach(item => {
      item.dataType = 1
    })
    ;(this.assignedUser = props?.assignedUser),
      (this.approvalSet = approvalType === 'ASSIGN_USER' ? '1' : approvalType === 'LEADER' ? '2' : '3'),
      (this.mode = props?.mode === 'OR' ? '2' : '1'),
      // 主管层级
      (this.level = this.approvalSet === '2' ? props?.leader.level : props?.leaderTop?.endLevel || 1)
    this.nobody = props?.nobody.handler === 'TO_PASS' ? '1' : '2'
    this.repeatPass = props?.repeatPass ? '1' : '2'
  },
  methods: {
    // 添加成员
    addPerson() {
      this.RangeModalVisible = true
    },
    // 删除成员
    delPerson(index) {
      this.assignedUser.splice(index, 1)
      console.log(this.assignedUser)
      console.log(this.selectedNode.props)
    },
    //搜索
    getSearchInfo(e) {
      let data = {
        searchName: e
      }
      this.getScopeList(data)
    },
    //去下级
    toNext(id) {
      let data = {
        departmentId: id
      }
      this.getScopeList(data)
    },
    //弹窗取消
    handleCancel() {
      this.RangeModalVisible = false
      this.$refs.globalSelector.cancelEvent()
    },
    //弹窗确定
    handleOk() {
      let arr = [...this.$refs.globalSelector.okEvent()]
      this.assignedUser = []
      transformData(arr).then(res => {
        res.forEach(item => {
          this.assignedUser.push({
            dataId: item.dataId,
            dataName: item.dataName,
            dataType: 1,
            sex: null,
            selected: true,
            icon: item?.icon,
            mobile: item?.mobile
          })
        })
      })
      this.handleCancel()
    },
    //弹窗数据
    async getScopeList(data) {
      let params = {
        selectorType: this.selectorType, //1部门人员2部门3员工
        allData: true
      }
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        console.log('全部数据', res)
        this.handelData(res)
      }
    },
    // 处理左侧数据
    handelData(res) {
      this.renderList = formateListData(res)
    },
    changeData({ isOrganizationData, searchValue }) {
      console.log('切换的数据的类型', isOrganizationData)
      if (isOrganizationData) {
        // 组织架构数据
        this.selectorType = 1
      } else {
        //人员数据
        this.selectorType = 3
      }
      this.getSearchInfo(searchValue)
    }
  }
}
</script>

<style scoped lang="less">
.form {
  :deep(.ant-form label) {
    font-size: 16px;
  }
  .form-app {
    margin-bottom: 32px;
  }
  .app-text {
    color: rgba(0, 0, 0, 0.25);
  }
  .person {
    .person-header {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      .person-header-right {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .personel {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-flow: wrap;
    margin: 4px 0px 32px;
    .per-item {
      background-color: #fbfbfc;
      border: 1px solid #dcdee0;
      color: rgba(0, 0, 0, 0.65);
      padding: 2px 4px;
      font-size: 14px;
      margin-right: 12px;
      margin-bottom: 12px;
      border-radius: 2px;
      .item-icon {
        font-size: 12px;
        color: #969799;
      }
    }
    .icon-add-right {
      color: #0A7BFF;
      cursor: pointer;
      font-size: 24px;
      margin-top: -6px;
    }
  }
  .text {
    background-color: #fbfbfb;
    border-radius: 2px;
    padding: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    line-height: 26px;
    margin-top: -24px;
    margin-bottom: 40px;
  }
}
</style>
